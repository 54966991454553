<template>
  <article id="checklist" class="Participants">
    <template v-for="(p, i) in participants">
      <input
        :key="`input-${i}`"
        :id="`p-${i}`"
        type="checkbox"
        v-model="p.hasTalked"
        @change="handleUpdate(p)"
      >

      <label
        :key="`label-${i}`"
        :for="`p-${i}`"
      >
        {{ p.name }}
      </label>
    </template>
  </article>
</template>

<script>
import { db, getParticipant } from '@/db'

export default {
  name: 'Participants',

  data() {
    return {
      participants: [],
    }
  },

  firebase: {
    participants: db.ref('participants')
  },

  methods: {
    handleUpdate(participant) {
      const dbRef = getParticipant(participant['.key'])
      dbRef.update({ hasTalked: participant.hasTalked })
    }
  }
}
</script>