// Get a RTDB instance
import firebase from 'firebase/app'
import 'firebase/database'

export const db = firebase
  .initializeApp({ databaseURL: 'https://acc-daily-default-rtdb.europe-west1.firebasedatabase.app/' })
  .database()

export function getParticipant (id) {
  return firebase.database().ref(`participants/${id}`)
}

export function resetAll (participants) {
  const dbRef = firebase.database().ref('participants')

  const newData = participants.map(item => {
    item.hasTalked = false

    return item
  })

  dbRef.set(newData)
}