<template>
  <div id="app">
    <h3>Daily List</h3>

    <user-list />

    <p style="text-align:center">
      <button type="button" @click="reset">
        Reset
      </button>
    </p>
  </div>
</template>

<script>
import UserList from '@/components/UserList'
import { db, resetAll } from '@/db'

export default {
  name: 'App',

  components: {
    UserList,
  },

  data() {
    return {
      participants: [],
    }
  },

  firebase: {
    participants: db.ref('participants')
  },

  methods: {
    reset() {
      resetAll(this.participants)
    },
  },
}
</script>

<style lang="scss">
h3 {
  text-align: center;
  opacity: 0.3;
  color: #414856;
}

button {
  outline: 0;
  border-radius: 3px;
  background-color: #ce0902;
  color: #eee;
  padding: 8px 12px;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background-color: #e40b03;
    color: #fff;
  }
}

#checklist {
  --background: #ffffff;
  --text: #414856;
  --check: #4F29F0;
  --disabled: #C3C8DE;
  --width: 150px;
  --border-radius: 10px;
  background: var(--background);
  width: var(--width);
  border-radius: var(--border-radius);
  position: relative;
  box-shadow: 0 10px 30px rgba(#414856, 0.05);
  padding: 30px 45px;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;

  label {
    color: var(--text);
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    width: fit-content;
    transition: color .3s ease;
    margin-bottom: 10px;

    &::before,
    &::after {
      content:"";
      position: absolute;
    }

    &::before {
      height: 2px;
      width: 8px;
      left: -27px;
      background: var(--check);
      border-radius: 2px;
      transition: background .3s ease; 
    }

    &::after {
      height: 4px;
      width: 4px;
      top: 5px;
      left: -25px;
      border-radius: 50%;
    }
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    height: 15px;
    width: 15px;
    outline: none;
    border: 0;
    margin: 0 15px 10px 0;
    cursor: pointer;
    background: var(--background);
    display: grid;
    align-items: center;

    &::before,
    &::after {
      content:"";
      position: absolute;
      height: 2px;
      top: auto;
      background: var(--check);
      border-radius: 2px;
    }

    &::before {
      width: 0px;
      right: 60%;
      transform-origin: right bottom;
    }

    &::after {
      width: 0px;
      left: 40%;
      transform-origin: left bottom;
    }

    &:checked {
      &::before {
        animation: check-01 .4s ease forwards;
      }

      &::after {
        animation: check-02 .4s ease forwards;
      }

      + label {
        color: var(--disabled);
        animation: move .3s ease .1s forwards;

        &::before {
          background: var(--disabled);
          animation: slice .4s ease forwards;
        }

        &::after {
          animation: firework .5s ease forwards .1s;
        }
      }
    }
  }
}

@keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}
@keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px #4F29F0, 0 0 0 -2px #4F29F0, 0 0 0 -2px #4F29F0, 0 0 0 -2px #4F29F0, 0 0 0 -2px #4F29F0, 0 0 0 -2px #4F29F0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px #4F29F0, 14px -8px 0 0px #4F29F0, 14px 8px 0 0px #4F29F0, 0 15px 0 0px #4F29F0, -14px 8px 0 0px #4F29F0, -14px -8px 0 0px #4F29F0;
  }
}

//--- ## BASIC #############
body {
  background: #E8EBF3;
  height: 100vh;
  font: 400 16px 'Varela Round', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>